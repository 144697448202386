@import "./src/styles/app-variables";

.card {

    h6 {
        line-height: 24px;

        a {
            text-decoration: none;
        }
    }

    .discountIcon {
        height: 14px;
        width: 14px;
        margin: 0 0.25rem 0.125rem 0;
    }

    .image {
        height: $card-img-height;
        object-fit: cover;
    }

}
