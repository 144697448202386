
$card-img-height: 9.375rem;

// Duplicate from bootstrap for usage in components
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
