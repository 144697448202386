@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// Light mode
$primary: #2c7be5;
$secondary: #5b7190;
$danger: #e74a3b;
$accent-1: #ffffff;
$accent-2: #d2ddec;
$accent-3: #5b7190;

$hr-border-color: #e3ebf6;
$section-border-color: #e8e8e8;

$gray-100: #f8f9fc;
$gray-200: #edf2f9;
$gray-300: #dddfeb;
$gray-400: #d1d3e2;
$gray-500: #b7b9cc;
$gray-600: #8a8989;
$gray-700: #6e707e;
$gray-800: #5a5c69;
$gray-900: #12263f;

$gradient: linear-gradient(135deg, #2260f8 0%, #9053fe 100%);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "accent-1": $accent-1,
    "accent-2": $accent-2,
    "accent-3": $accent-3,
);

$body-bg: #f0f2f5;
$body-color: #5b7190;
$input-bg: #fff;
$input-focus-bg: $input-bg;

// Dark mode
html[darkmode] {

    $primary: #704cb6;
    $secondary: #5b7190;
    $danger: #e74a3b;
    $accent-1: #212529;
    $accent-2: #d2ddec;
    $accent-3: #5b7190;

    $theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "accent-1": $accent-1,
        "accent-2": $accent-2,
        "accent-3": $accent-3,
    );

    @each $color, $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

    @each $color, $value in $theme-colors-rgb {
        --#{$variable-prefix}#{$color}-rgb: #{$value};
    }

    --#{$variable-prefix}body-color: #{$body-bg};
    --#{$variable-prefix}body-bg: #{$body-color};

    --#{$variable-prefix}body-color-rgb: #{to-rgb($body-bg)};
    --#{$variable-prefix}body-bg-rgb: #{to-rgb($body-color)};
}

// Common
$border-color: $gray-200;
$border-color-translucent: rgba($body-color, .1);

$box-shadow-sm: 0 .125rem .125rem rgba($black, .075);

$card-border-width: 0;

$font-family-base: Roboto, #{$font-family-base};
$font-size-base: .85rem;

$headings-font-weight: $font-weight-semibold;

$hr-opacity: 1;

$link-decoration: none;

$min-contrast-ratio: 4;

$nav-link-font-size: 1rem;

$paragraph-margin-bottom: .85rem;

$small-font-size: .8125rem;

$text-muted: $gray-500;
