@import "./src/styles/app-variables";

.card {

    h6 {
        line-height: 24px;

        a {
            text-decoration: none;
        }
    }

    .badge {
        line-height: 12px;
    }

    .discountIcon {
        height: 14px;
        width: 14px;
    }

    .image {
        height: $card-img-height;
        object-fit: cover;
    }

    .shape {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        svg {
            display: block;
            width: 100%;
            height: auto;
            transform: scale(2);
            transform-origin: top center;
        }
    }

}
