
.button {
    bottom: -16px;
    left: 1rem !important;
    position: absolute;

    img {
        height: 35px;
        width: 35px;
        background: #fff;
        object-fit: contain;
        padding: 0.15rem;
    }

    span {
        height: 35px;
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;
        vertical-align: middle;
    }
}
