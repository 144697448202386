
h6 {
    line-height: 1.5;
}

section {
    min-width: 280px;
}

.bg-gray-100 {
    background-color: $gray-100 !important;
}

.font-size-xs {
    font-size: 0.7rem !important;
}
.font-weight-light {
    font-weight: $font-weight-light !important;
}
.font-weight-normal {
    font-weight: $font-weight-normal !important;
}
.font-weight-bold {
    font-weight: $font-weight-bold !important;
}

.footer {
    a {
        color: #7a7e8a;
        letter-spacing: 0.4px;
        font-weight: 300;

        &:hover {
            color: #000000;
        }
    }

    h6 {
        font-size: 0.75rem;
        margin: 0 0 1rem;
        text-transform: uppercase;
        letter-spacing: 0.6px;
    }

    li {
        line-height: 32px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.section-padding {

    // Mobile View
    border-bottom: 1px solid $section-border-color;
    padding: 20px 0;

    // Desktop View
    @media screen and (min-width: map-get($grid-breakpoints, sm)) {
        border-bottom: none;
        padding: 30px 0;
    }
}

.text-gray-400 {
    color: $gray-400 !important;
}
.text-gray-500 {
    color: $gray-500 !important;
}
.text-gray-600 {
    color: $gray-600 !important;
}
.text-gray-900 {
    color: $gray-900 !important;
}

.react-multi-carousel-track {
    padding-bottom: 0.5rem;
}

// TODO: move out following styles to corresponding component

.top-navbar-right {
    // Mobile View
    border-top: 1px solid $section-border-color;
    margin-top: 0.25rem;
    padding-top: 0.25rem;

    // Desktop View
    @media screen and (min-width: map-get($grid-breakpoints, md)) {
        border-top: inherit;
        margin-top: inherit;
        padding-top: inherit;
    }
}

// Mobile View
@media screen and (min-width: 0) and (max-width: map-get($grid-breakpoints, md) - 1px) {

    h1, .h1 {
        font-size: 2rem;
    }
    h5, .h5 {
        font-size: 1rem;
    }
    h6, .h6 {
        font-size: 0.875rem;
    }

}
