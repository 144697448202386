
.react-multi-carousel-list {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.react-multi-carousel-item {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.react-multiple-carousel__arrow {

    background-color: $white;
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.25);

    min-width: 35px;
    min-height: 35px;

    &:before {
        color: $body-color;
    }

    &:hover {
        background-color: $primary;

        &:before {
            color: $white;
        }
    }

    &.react-multiple-carousel__arrow--left {
        left: 0;
    }

    &.react-multiple-carousel__arrow--right {
        right: 0;
    }

}
