@import "./src/styles/app-variables";

.title {
    // Mobile View
    font-size: 2.5rem;

    // Desktop View
    @media screen and (min-width: map-get($grid-breakpoints, md)) {
        font-size: 3.25rem;
    }
}
