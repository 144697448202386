
.container {
    display: flex;
}

.content {
    flex: 1 0 auto;
}

.sidebar {
    box-shadow: 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.075);
    margin: 0.125rem 1rem 0.125rem 0;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.submenu {
    padding: 0.25rem 0;
    border-bottom: 1px solid #d2ddec;
    border-top: 1px solid #d2ddec;
}
