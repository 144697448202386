
.card {

    .img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        background-color: #bbb;
        margin: auto;
    }

}
